<template lang="pug">
  #intolerances-container
    input(type='hidden' name='order_item[intolerances]' :value="JSON.stringify(data)" v-if="intolerancesActive")
    input(type='hidden' name='order_item[intolerances]' value="[]" v-else)
    .input-wrapper
      input#intolerances-on(type='checkbox' name='order_item[intolerance_active]' value="true" v-model="intolerancesActive")
      span.custom-toggle
      label(for='intolerances-on'): span( style="margin-right: 40px;") Gäste mit Unverträglichkeiten eintragen (optional)
    #intolerances-content(:class="intolerancesActive ? '' : 'is--hidden'")
      .icon-text.is--warning(v-if="countError")
        svg(fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg')
          path(d='M11.25 16.75H12.75V11H11.25V16.75ZM12 9.28845C12.2288 9.28845 12.4207 9.21105 12.5755 9.05625C12.7303 8.90145 12.8077 8.70963 12.8077 8.48078C12.8077 8.25194 12.7303 8.06012 12.5755 7.9053C12.4207 7.7505 12.2288 7.6731 12 7.6731C11.7711 7.6731 11.5793 7.7505 11.4245 7.9053C11.2697 8.06012 11.1923 8.25194 11.1923 8.48078C11.1923 8.70963 11.2697 8.90145 11.4245 9.05625C11.5793 9.21105 11.7711 9.28845 12 9.28845ZM12.0016 21.5C10.6877 21.5 9.45268 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5765 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45268 3.248 8.29655C3.74667 7.1404 4.42342 6.13472 5.27825 5.2795C6.1331 4.42427 7.13834 3.74721 8.29398 3.24833C9.44959 2.74944 10.6844 2.5 11.9983 2.5C13.3122 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8652 4.42342 18.7205 5.27825C19.5757 6.1331 20.2527 7.13834 20.7516 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0016 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76664 19.225 7.87498 17.675 6.32498C16.125 4.77498 14.2333 3.99998 12 3.99998C9.76664 3.99998 7.87498 4.77498 6.32498 6.32498C4.77498 7.87498 3.99998 9.76664 3.99998 12C3.99998 14.2333 4.77498 16.125 6.32498 17.675C7.87498 19.225 9.76664 20 12 20Z')
        .icon-text-content
          p Die von Ihnen angegebenen Arten von Unverträglichkeiten passen nicht zur Verteilung von klassischen und vegetarischen Menüs.
      .fieldsets
        fieldset.guest-intolerances.icon-text.is--highlight.is--inverted(v-for="(d, i) in data")
          svg.user-icon(width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M12 11.6922C11.0375 11.6922 10.2135 11.3495 9.52813 10.6641C8.84271 9.97873 8.5 9.15478 8.5 8.1923C8.5 7.2298 8.84271 6.40585 9.52813 5.72045C10.2135 5.03503 11.0375 4.69232 12 4.69232C12.9625 4.69232 13.7864 5.03503 14.4718 5.72045C15.1572 6.40585 15.5 7.2298 15.5 8.1923C15.5 9.15478 15.1572 9.97873 14.4718 10.6641C13.7864 11.3495 12.9625 11.6922 12 11.6922ZM4.5 19.3076V17.0846C4.5 16.5948 4.63302 16.1413 4.89905 15.724C5.16507 15.3067 5.52051 14.9859 5.96537 14.7615C6.95384 14.2769 7.95096 13.9134 8.95672 13.6711C9.96249 13.4288 10.9769 13.3077 12 13.3077C13.023 13.3077 14.0375 13.4288 15.0432 13.6711C16.049 13.9134 17.0461 14.2769 18.0346 14.7615C18.4794 14.9859 18.8349 15.3067 19.1009 15.724C19.3669 16.1413 19.5 16.5948 19.5 17.0846V19.3076H4.5ZM5.99997 17.8076H18V17.0846C18 16.882 17.9413 16.6945 17.824 16.5221C17.7067 16.3496 17.5474 16.2089 17.3461 16.0999C16.4846 15.6756 15.6061 15.3541 14.7107 15.1355C13.8152 14.9169 12.9117 14.8076 12 14.8076C11.0883 14.8076 10.1847 14.9169 9.28927 15.1355C8.39384 15.3541 7.51536 15.6756 6.65382 16.0999C6.45254 16.2089 6.29325 16.3496 6.17595 16.5221C6.05863 16.6945 5.99997 16.882 5.99997 17.0846V17.8076ZM12 10.1923C12.55 10.1923 13.0208 9.99646 13.4125 9.6048C13.8041 9.21313 14 8.7423 14 8.1923C14 7.6423 13.8041 7.17146 13.4125 6.7798C13.0208 6.38813 12.55 6.1923 12 6.1923C11.45 6.1923 10.9791 6.38813 10.5875 6.7798C10.1958 7.17146 9.99997 7.6423 9.99997 8.1923C9.99997 8.7423 10.1958 9.21313 10.5875 9.6048C10.9791 9.99646 11.45 10.1923 12 10.1923Z')
          .icon-text-content
            .legend-container
              legend Gast {{pad(i+1, 3)}}
              button.btn.is--inverted.is--tertiary.is--icon.is--remove(type='button' @click="remove(d.id)")
                svg(width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
                  path(d='M7.275 18L6 16.725L10.725 12L6 7.275L7.275 6L12 10.725L16.725 6L18 7.275L13.275 12L18 16.725L16.725 18L12 13.275L7.275 18Z')
            .input-wrapper.is--radioslide
              input(type='radio' :name="'intolerances_'+i+'_menu_type'" :id="'intolerances_'+i+'_menu_type_classic'" value='classic' v-model="d.type")
              label(:for="'intolerances_'+i+'_menu_type_classic'") Klassisch
              input(type='radio' :name="'intolerances_'+i+'_menu_type'" :id="'intolerances_'+i+'_menu_type_veggie'" value='veggie' v-model="d.type")
              label(:for="'intolerances_'+i+'_menu_type_veggie'") Vegetarisch
            .settings
              .input-wrapper
                input(type='checkbox' :name="'intolerances_'+i+'_gluten'" :id="'intolerances_'+i+'_gluten'" v-model="d.gluten")
                span.custom-checkbox
                  svg(width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
                    path(d='M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z')
                label(:for="'intolerances_'+i+'_gluten'") Glutenunverträglichkeit
              .input-wrapper
                input(type='checkbox' :name="'intolerances_'+i+'_nuts'" :id="'intolerances_'+i+'_nuts'" v-model="d.nuts")
                span.custom-checkbox
                  svg(width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
                    path(d='M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z')
                label(:for="'intolerances_'+i+'_nuts'") Nussallergie
              .input-wrapper
                input(type='checkbox' :name="'intolerances_'+i+'_fructose'" :id="'intolerances_'+i+'_fructose'" v-model="d.fructose")
                span.custom-checkbox
                  svg(width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
                    path(d='M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z')
                label(:for="'intolerances_'+i+'_fructose'") Fructoseintoleranz
              .input-wrapper
                input(type='checkbox' :name="'intolerances_'+i+'_other'" :id="'intolerances_'+i+'_other'" v-model="d.other")
                span.custom-checkbox
                  svg(width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
                    path(d='M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z')
                label(:for="'intolerances_'+i+'_other'") Sonstiges

            div(v-if="d.other" style="margin-top: 30px;")  
              label(:for="'intolerances_'+i+'_other_details'") Bitte tragen Sie nur die sonstigen Unverträglichkeiten ein:
              .input-wrapper(:id="'intolerances_'+i+'_other_details_container'")
                textarea(:name="'intolerances_'+i+'_other_details'" :id="'intolerances_'+i+'_other_details'" rows='5' v-model="d.other_details")
      button#add-intolerance-person.btn.is--inverted.is--tertiary(type='button' @click="addNew" v-if="menuCount + veganCount != this.data.length")
        span Gast mit Unverträglichkeiten hinzufügen
        svg(fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg')
          path(d='M11.25 12.75H5.5V11.25H11.25V5.5H12.7499V11.25H18.5V12.75H12.7499V18.5H11.25V12.75Z')
</template>

<script>
export default {
  components: {
  },
  props: [
    "menuCount",
    "veganCount",
    "modelValue"    
  ],
  data () {
    return {
      intolerancesActive: false,
      show: false,
      data: [],
      base: {type: "classic", gluten: false, nuts: false, fructose: false, other: false, other_details: ""}
    }
  },
  mounted () {
  },
  watch: {
    modelValue(){
      this.data = this.modelValue
      this.intolerancesActive = this.data.length != 0
    },
    intolerancesActive(){
      if (this.intolerancesActive && this.data.length == 0){
        this.data = [JSON.parse(JSON.stringify(this.base))]
      }
    }
  },
  computed: {
    countError(){
      if (this.data.length == 0) return false
      const error = this.data.filter(x => x.type == "classic").length > this.menuCount || this.data.filter(x => x.type == "veggie").length > this.veganCount
      this.$emit('update:buttonDisabled', error)
      return error
    }
  },
  methods: {
    remove(id){
      this.data = this.data.filter(x => x.id != id)
    },
    pad(n, len) {
      return (new Array(len + 1).join('0') + n).slice(-len);
    },
    addNew(){
      let element = JSON.parse(JSON.stringify(this.base))
      element.id = (Math.random() + 1).toString(36).substring(7)
      this.data.push(element)
    }
  }
}
</script>
